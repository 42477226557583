import { getUntracked, markToTrack } from 'proxy-compare';

const VERSION = Symbol();
const LISTENERS = Symbol();
const SNAPSHOT = Symbol();
const PROMISE_RESULT = Symbol();
const PROMISE_ERROR = Symbol();
const refSet = new WeakSet();
const ref = (o) => {
  refSet.add(o);
  return o;
};
const isSupportedObject = (x) => typeof x === "object" && x !== null && (Array.isArray(x) || !x[Symbol.iterator]) && !(x instanceof WeakMap) && !(x instanceof WeakSet) && !(x instanceof Error) && !(x instanceof Number) && !(x instanceof Date) && !(x instanceof String) && !(x instanceof RegExp) && !(x instanceof ArrayBuffer);
const proxyCache = new WeakMap();
let globalVersion = 1;
const snapshotCache = new WeakMap();
const proxy = (initialObject = {}) => {
  if (!isSupportedObject(initialObject)) {
    throw new Error("unsupported object type");
  }
  const found = proxyCache.get(initialObject);
  if (found) {
    return found;
  }
  let version = globalVersion;
  const listeners = /* @__PURE__ */ new Set();
  const notifyUpdate = (op, nextVersion) => {
    if (!nextVersion) {
      nextVersion = ++globalVersion;
    }
    if (version !== nextVersion) {
      version = nextVersion;
      listeners.forEach((listener) => listener(op, nextVersion));
    }
  };
  const propListeners = /* @__PURE__ */ new Map();
  const getPropListener = (prop) => {
    let propListener = propListeners.get(prop);
    if (!propListener) {
      propListener = (op, nextVersion) => {
        const newOp = [...op];
        newOp[1] = [prop, ...newOp[1]];
        notifyUpdate(newOp, nextVersion);
      };
      propListeners.set(prop, propListener);
    }
    return propListener;
  };
  const popPropListener = (prop) => {
    const propListener = propListeners.get(prop);
    propListeners.delete(prop);
    return propListener;
  };
  const createSnapshot = (target, receiver) => {
    const cache = snapshotCache.get(receiver);
    if ((cache == null ? void 0 : cache[0]) === version) {
      return cache[1];
    }
    const snapshot2 = Array.isArray(target) ? [] : Object.create(Object.getPrototypeOf(target));
    markToTrack(snapshot2, true);
    snapshotCache.set(receiver, [version, snapshot2]);
    Reflect.ownKeys(target).forEach((key) => {
      const value = target[key];
      if (refSet.has(value)) {
        markToTrack(value, false);
        snapshot2[key] = value;
      } else if (!isSupportedObject(value)) {
        snapshot2[key] = value;
      } else if (value instanceof Promise) {
        if (PROMISE_RESULT in value) {
          snapshot2[key] = value[PROMISE_RESULT];
        } else {
          const errorOrPromise = value[PROMISE_ERROR] || value;
          Object.defineProperty(snapshot2, key, {
            get() {
              if (PROMISE_RESULT in value) {
                return value[PROMISE_RESULT];
              }
              throw errorOrPromise;
            }
          });
        }
      } else if (value[VERSION]) {
        snapshot2[key] = value[SNAPSHOT];
      } else {
        snapshot2[key] = value;
      }
    });
    Object.freeze(snapshot2);
    return snapshot2;
  };
  const baseObject = Array.isArray(initialObject) ? [] : Object.create(Object.getPrototypeOf(initialObject));
  const proxyObject = new Proxy(baseObject, {
    get(target, prop, receiver) {
      if (prop === VERSION) {
        return version;
      }
      if (prop === LISTENERS) {
        return listeners;
      }
      if (prop === SNAPSHOT) {
        return createSnapshot(target, receiver);
      }
      return target[prop];
    },
    deleteProperty(target, prop) {
      const prevValue = target[prop];
      const childListeners = prevValue == null ? void 0 : prevValue[LISTENERS];
      if (childListeners) {
        childListeners.delete(popPropListener(prop));
      }
      const deleted = Reflect.deleteProperty(target, prop);
      if (deleted) {
        notifyUpdate(["delete", [prop], prevValue]);
      }
      return deleted;
    },
    set(target, prop, value) {
      var _a;
      const prevValue = target[prop];
      if (Object.is(prevValue, value)) {
        return true;
      }
      const childListeners = prevValue == null ? void 0 : prevValue[LISTENERS];
      if (childListeners) {
        childListeners.delete(popPropListener(prop));
      }
      if (refSet.has(value) || !isSupportedObject(value) || ((_a = Object.getOwnPropertyDescriptor(target, prop)) == null ? void 0 : _a.set)) {
        target[prop] = value;
      } else if (value instanceof Promise) {
        target[prop] = value.then((v) => {
          target[prop][PROMISE_RESULT] = v;
          notifyUpdate(["resolve", [prop], v]);
          return v;
        }).catch((e) => {
          target[prop][PROMISE_ERROR] = e;
          notifyUpdate(["reject", [prop], e]);
        });
      } else {
        value = getUntracked(value) || value;
        if (value[LISTENERS]) {
          target[prop] = value;
        } else {
          target[prop] = proxy(value);
        }
        target[prop][LISTENERS].add(getPropListener(prop));
      }
      notifyUpdate(["set", [prop], value, prevValue]);
      return true;
    }
  });
  proxyCache.set(initialObject, proxyObject);
  Reflect.ownKeys(initialObject).forEach((key) => {
    const desc = Object.getOwnPropertyDescriptor(initialObject, key);
    if (desc.get || desc.set) {
      Object.defineProperty(baseObject, key, desc);
    } else {
      proxyObject[key] = initialObject[key];
    }
  });
  return proxyObject;
};
const getVersion = (proxyObject) => proxyObject[VERSION];
const subscribe = (proxyObject, callback, notifyInSync) => {
  if (typeof process === "object" && process.env.NODE_ENV !== "production" && !(proxyObject == null ? void 0 : proxyObject[LISTENERS])) {
    console.warn("Please use proxy object");
  }
  let promise;
  const ops = [];
  const listener = (op) => {
    ops.push(op);
    if (notifyInSync) {
      callback(ops.splice(0));
      return;
    }
    if (!promise) {
      promise = Promise.resolve().then(() => {
        promise = void 0;
        callback(ops.splice(0));
      });
    }
  };
  proxyObject[LISTENERS].add(listener);
  return () => {
    proxyObject[LISTENERS].delete(listener);
  };
};
const snapshot = (proxyObject) => {
  if (typeof process === "object" && process.env.NODE_ENV !== "production" && !(proxyObject == null ? void 0 : proxyObject[SNAPSHOT])) {
    console.warn("Please use proxy object");
  }
  return proxyObject[SNAPSHOT];
};

export { getVersion, proxy, ref, snapshot, subscribe };
